import './components.css'

const Maincard = ({data = [], loading}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="align-self-center col-lg-2"></div>
                            <div className="align-self-center col-lg-8">
                                <div className="text-lg-center mt-4 mt-lg-0">
                                    <div className="row" style={{justifyContent: 'space-between'}}>
                                        <div className="col-2">
                                            <div className='maincard-border'>
                                                <p className="text-muted text-truncate mb-2">New Order</p>
                                                {
                                                    !loading ? (
                                                        <>
                                                            <h5 className="mb-0">{data?.new || 0}</h5>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5 className="card-title placeholder-glow"><span className="placeholder col-6"></span></h5>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className='maincard-border'>
                                                <p className="text-muted text-truncate mb-2">Redo Order</p>
                                                {
                                                    !loading ? (
                                                        <>
                                                            <h5 className="mb-0">{data?.redo  || 0}</h5>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5 className="card-title placeholder-glow"><span className="placeholder col-6"></span></h5>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className='maincard-border'>
                                                <p className="text-muted text-truncate mb-2">Correction Order</p>
                                                {
                                                    !loading ? (
                                                        <>
                                                            <h5 className="mb-0">{data?.corr  || 0}</h5>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5 className="card-title placeholder-glow"><span className="placeholder col-6"></span></h5>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className='maincard-border'>
                                                <p className="text-muted text-truncate mb-2">Total Orders</p>
                                                {
                                                    !loading ? (
                                                        <>
                                                            <h5 className="mb-0">{data?.total_orders  || 0}</h5>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5 className="card-title placeholder-glow"><span className="placeholder col-6"></span></h5>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className='maincard-border'>
                                                <p className="text-muted text-truncate mb-2">Total Amount</p>
                                                {
                                                    !loading ? (
                                                        <>
                                                            <h5 className="mb-0">{data?.total_amount  || 0}</h5>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5 className="card-title placeholder-glow"><span className="placeholder col-6"></span></h5>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Maincard;