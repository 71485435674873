const formatReferenceNumber = (reference) => {
    let refStr = reference.toString();
    let yearPart1 = refStr.slice(0, 4);
    let yearPart2 = (parseInt(yearPart1) + 1).toString().slice(-2);
    let identifier = refStr.slice(6);

    return `${yearPart1}-${yearPart2} / ${identifier}`;
}

const customRound = (num) => {
    let decimalPart = num % 1; // Get the decimal part
    let integerPart = Math.floor(num); // Get the integer part

    if (decimalPart == 0 && decimalPart < 0.5) {
       return integerPart + 0;
    } else {
       return integerPart + 1;
    }
}

export { formatReferenceNumber, customRound }